









import { Component, Vue } from 'vue-property-decorator'
import classroomData from '../../../assets/data/classroom'

@Component({
  components: {  }
})
export default class extends Vue{
  private scenario = classroomData.scenario
}


