









import { Component, Vue } from 'vue-property-decorator'
import vFreeApply from '@/components/v-free-apply.vue'
import classroomAdvantage from './components/advantage.vue'
import classroomScenario from './components/scenario.vue'

@Component({
  components: { vFreeApply, classroomAdvantage, classroomScenario }
})
export default class extends Vue{

}


