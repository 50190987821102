export default {
  video: {
    src: 'https://video.xmheigu.com/customerTrans/90f04d377e5350e3ba2915544f67aa2d/29cd6f15-179f99e86b3-0005-502e-0e5-a9c95.mp4',
    poster: require('../images/public/poster/classroom.jpg'),
    title: '',
    dec: ''
  },
  advantage: {
    title: '实战优势',
    data: [
      '① 专业专家深度剖析经营难题',
      '② 同行实战经营分享成功经验',
      '③ 最前沿商业模式助力营业增长',
      '④ 专业策划团队助力落地实施'
    ]
  },
  scenario:[
    require('../images/classroom/scenario_01.jpg'),
    require('../images/classroom/scenario_02.jpg'),
    require('../images/classroom/scenario_03.jpg'),
    require('../images/classroom/scenario_04.jpg'),
    require('../images/classroom/scenario_05.jpg'),
    require('../images/classroom/scenario_06.jpg'),
    require('../images/classroom/scenario_07.jpg'),
    require('../images/classroom/scenario_08.jpg'),
    require('../images/classroom/scenario_09.jpg'),
    require('../images/classroom/scenario_10.jpg'),
    require('../images/classroom/scenario_11.jpg'),
    require('../images/classroom/scenario_12.jpg'),
  ]
}