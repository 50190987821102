














import { Component, Vue } from 'vue-property-decorator'
import VVideoModule from '@/components/v-video-module.vue'
import classroomData from '../../../assets/data/classroom'

@Component({
  components: { VVideoModule }
})
export default class extends Vue{
  private video = classroomData.video
  private advantage = classroomData.advantage
}


